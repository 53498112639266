@use 'styles/_variables.scss' as *;
.productItem {
  position: relative;
  padding: $global-margin * 2 $global-margin * 7 $global-margin * 2 $global-margin * 2;

  p {
    font-family: $copy-font-family;
  }

  @media (max-width: $m-screen) {
    padding: $global-margin * 2;
  }

  @media (max-width: $s-screen) {
    h3 {
      font-size: $base-font-size;
    }
  }

  .title {
    overflow-wrap: break-word;
  }

  &__adultsOnly {
    position: absolute;
    top: -1px;
    left: 0;
    width: 4.5rem;
    height: 4.5rem;
    padding: 0.5rem;
    border-top: 1px solid $pale-gray-4;
    border-left: 1px solid $pale-gray-4;

    &:after {
      content: "";
      display: block;
      height: 100px;
      width: 1px;
      transform: rotate(45deg);
      background-color: $pale-gray-4;
      position: absolute;
      left: 35px;
      bottom: -14px;
    }

    i {
      font-size: 1.5rem;
      color: $almost-black-60;
    }
  }

  &__cover {
    padding: 0 $global-padding * 2 0 $global-padding;

    @media (max-width: $m-screen) {
      height: 100%;
      max-height: 135px;
      max-width: 85px;
      padding: 0;
      width: 100%;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $pale-gray-4;
  }

  h3 {
    margin: 0 0 $global-margin * 0.625;
  }

  &__rating {
    display: flex;
    margin-top: $global-margin;
    align-items: center;

    &__count {
      font-size: $small-font-size;
      padding-left: $global-padding * 0.5;
    }
  }

  &__published {
    font-size: $small-font-size;
    color: $almost-black-60;
    line-height: 1.33;
    margin: calc($global-margin / 1.6) 0 0;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $copy-font-family;
    font-size: $small-font-size;
    gap: $global-margin;

    p {
      font-family: $brand-font-family;
      font-weight: bold;
    }

    &__type {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: $small-font-size;

      i {
        font-size: 1.5rem;
        color: $almost-black-60;
        margin-right: $global-margin * 0.5;
      }
    }

    &__promotion {
      display: flex;
      align-items: center;
    }

    &__previous {
      margin: 0 $global-padding * 0.5;
      opacity: 0.6;
      color: $almost-black;
      text-decoration: line-through;
    }

    & > *:nth-child(2) {
      text-align: right;
    }
  }
}
