@use 'styles/_variables.scss' as *;
.productsPage {
  align-items: flex-start;
  box-sizing: border-box;
  margin: 3 * $global-margin auto 5 * $global-margin;
  max-width: 1360px;
  padding: 0;
  width: 100%;

  @media (max-width: 1360px) {
    padding: 0 2.5 * $global-padding;
  }

  &__explicitFilter {
    align-items: center;
    display: flex;

    label {
      color: $almost-black-70;
      cursor: pointer;
      font-family: $copy-font-family;
    }

    .icon-shop-explicit {
      font-size: 1.5rem;
      color: $almost-black-70;
      margin-left: $global-margin * 0.5;
    }
  }
}

.productsPagination {
  border-top: 1px solid $pale-gray-4;
  padding-top: $global-padding * 3;
  margin-bottom: $global-margin * 2;
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $global-padding * 0.5;
  margin-bottom: $global-margin;

  &__title {
    margin: 0;
  }

  &__toggle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: $global-border-radius;
    background-color: $indigo;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    i {
      color: $pale-gray-4;
    }
  }
}

.ageValidationLink {
  cursor: pointer;
  color: $indigo;

  &:hover {
    text-decoration: underline;
  }
}
