@use 'styles/_variables.scss' as *;
.searchFacet {
  margin-bottom: $global-margin * 3;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $global-margin;
    border-top: 1px solid $pale-gray-4;
    padding: $global-padding * 0.75 $global-margin * 0.5 0;

    p {
      margin: 0;
      font-weight: 900;
      color: $indigo;
    }

    &__toggle {
      width: 1.5rem;
      height: 1.5rem;
      background-color: $pale-gray-4;
      border-radius: $global-border-radius;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: $indigo;
      }
    }
  }

  &__options {
    max-height: 15rem;
    overflow-y: auto;
    position: relative;
    padding-bottom: $global-margin;
    border-bottom: 1px solid $pale-gray-4;
  }

  &__option {
    display: flex;
    font-family: $copy-font-family;
    justify-content: space-between;
    align-items: center;
    padding: 0 $global-padding * 0.75;

    &:hover {
      cursor: pointer;
      background-color: $pale-gray-2;
    }

    &__name {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: $indigo;

      &__language {
        &::first-letter {
          text-transform: uppercase;
        }
      }

      .star-rating {
        height: 26px;
        margin-right: calc($global-margin / 3);
      }
      line-height: 1.5;
    }

    &__count {
      font-size: $small-font-size;
      font-weight: bold;
      color: $almost-black-60;
      line-height: 2;
      display: flex;
      align-items: center;

      &__remove {
        font-size: 1.5rem;
        line-height: 1;
        color: $indigo;
      }
    }
  }

  &__showAll {
    text-align: right;
    color: $indigo;
    font-family: $copy-font-family;
    text-decoration: underline;
    line-height: 1.5rem;
    margin-top: $global-margin * 0.5;

    &:hover {
      cursor: pointer;
    }
  }
}
