@use 'styles/_variables.scss' as *;
.sortBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: $global-padding;
  border-bottom: 1px solid $pale-gray-4;

  @media (max-width: $m-screen) {
    margin-top: 0;
    padding-top: $global-padding;
    border-top: 2px solid $indigo;
    justify-content: space-between;
  }

  &__info {
    font-weight: 300;
    color: $almost-black-60;
    margin-right: $global-margin * 3;
  }

  &__dropdown {
    position: relative;

    &:hover {
      * {
        text-decoration: none;
      }

      .menuItem__dropdownMenu {
        display: block;
        top: 1.5rem;
        font-weight: 300;

        a {
          line-height: 1;

          &:not(:last-of-type) {
            margin-bottom: $global-margin * 0.5;
          }
        }
      }
    }

    font-weight: bold;

    span {
      font-weight: 300;
      color: $almost-black-60;
      margin-right: $global-margin * 0.5;
    }

    &__title {
      display: flex;
      align-items: center;
      line-height: 1.5;
    }
  }
}

.menuItem__dropdownMenu {
  display: none;
  position: absolute;
  top: 2.5 * $global-margin;
  min-width: 150px;
  background-color: $white;
  border: 1px solid $pale-gray-5;
  border-radius: $button-border-radius;
  box-shadow: $button-dropdown-shadow;
  line-height: 1.5;
  padding: $global-padding 0;
  z-index: 2;

  @media (max-width: $l-screen) {
    line-height: 2.5;
  }

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-bottom-color: $white;
    border-width: 8px;
    margin-left: -8px;
  }

  &:before {
    border-bottom-color: $pale-gray-5;
    border-width: 9px;
    margin-left: -9px;
  }

  &__label {
    opacity: 0.6;
  }

  a,
  div {
    display: block;
    padding: 0 1rem;
    cursor: pointer;
  }

  hr {
    border: 0;
    border-top: 1px solid $pale-gray-5;
  }
}
